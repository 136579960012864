import {Action} from "redux";
import {fetchServiceFail, fetchServicePending, fetchServiceSuccess,} from "./AppActions";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {RootState} from "../store/index";
import {GET_JSON} from "../ThunkUtils";

type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

function wakeAction(service: string): ThunkResult<void> {

    function mapResponseStateToStatusDown(response: Response) {
        if (response.status != 200) {
            return Promise.resolve({status: "DOWN"});
        } else {
            return response.json();
        }
    }

    function validateSubStatus(serviceResponse: any,
                               dispatch: ThunkDispatch<RootState, undefined, Action>,
                               url: string) {
        if ("components" in serviceResponse) {
            let loading = false;
            for (let key in serviceResponse.components) {
                if (serviceResponse.components[key].status === "LOADING") {
                    loading = true;
                    const timer = setInterval(() => {
                        fetchServiceHealth(url, dispatch);
                        clearInterval(timer!);
                    }, 2000);
                }
            }
            if (!loading) {
                dispatch(fetchServiceSuccess(service));
            }
        } else {
            dispatch(fetchServiceSuccess(service));
        }
    }

    function dispatchServiceStatus(url: string,
                                   serviceResponse: any,
                                   dispatch: ThunkDispatch<RootState, undefined, Action>) {
        try {
            if (!("status" in serviceResponse)) {
                fetchServiceHealth(url, dispatch);
            } else if (serviceResponse.status === 'UP') {
                validateSubStatus(serviceResponse, dispatch, url);
            } else {
                dispatch(fetchServiceFail(service));
            }
        } catch (reason) {
            fetchServiceHealth(url, dispatch);
        }
    }

    function fetchServiceHealth(url: string, dispatch: ThunkDispatch<RootState, undefined, Action>) {
        dispatch(fetchServicePending(service));
        fetch(`${url}actuator/health`, GET_JSON)
            .then(mapResponseStateToStatusDown)
            .then((serviceResponse) => dispatchServiceStatus(url, serviceResponse, dispatch));
    }

    return (dispatch: ThunkDispatch<RootState, undefined, Action>, getState: () => RootState) => {
        const serviceItem = getState().services.all.get(service);

        if (serviceItem) {
            fetchServiceHealth(serviceItem.url, dispatch);
        } else {
            dispatch(fetchServiceFail(service));
        }
    };
}

export default wakeAction;