import React from "react";
import Modal, {ModalProps} from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import MicroserviceLoadingView from "./MicroserviceLoadingView";

const MicroservicesLoadingView: React.FC<ModalProps> = (props: ModalProps) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton={false}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Chargement des ressources
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <MicroserviceLoadingView name={"Gateway Dvds"}/>
                    <MicroserviceLoadingView name={"Bibliothèque Dvds"}/>
                    <MicroserviceLoadingView name={"Fiches Dvd"}/>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default MicroservicesLoadingView;