import React from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {connect} from "react-redux";
import {IDispatchProps, mapDispatchToProps} from "./AlertDismissible.redux";
import {RootState} from "../redux/store/index";
import "./AlertDismissible.css"

interface IOwnProps {
    message: string;
}

type Props = IOwnProps & IDispatchProps;

export const AlertDismissibleComponent: React.FC<Props> = (props: Props) => {

    if(typeof(props.message) === 'undefined' || props.message === '') {
        return <></>;
    }

    return (
        <>
            <Alert show={true} variant="warning" className={"dvdAlert"}>
                {props.message}
                <Button onClick={() => props.setError("")} variant="outline-success" className={"float-right"}>
                    Fermer
                </Button>
            </Alert>
        </>
    );
};

export default connect<{}, IDispatchProps, {}, RootState>(null, mapDispatchToProps)(AlertDismissibleComponent);
