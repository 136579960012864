import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {RootState} from "../store/index";
import {Action} from "redux";
import {Dvd} from "../dto/Dvd";
import {deleteDvdFail, deleteDvdPending, deleteDvdSuccess} from "./AppActions";

type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

function deleteDvd(dvd: Dvd): ThunkResult<void> {

    return (dispatch: ThunkDispatch<RootState, undefined, Action>, getState: () => RootState) => {
        const bearer = getState().services.bearer;
        const options = {
            body: JSON.stringify(dvd),
            headers: new Headers({
                "Authorization": `Bearer ${bearer}`,
                "Content-Type": "application/json;charset=UTF-8",
            }),
            method: "delete",
        };

        dispatch(deleteDvdPending());
        fetch(`${process.env.REACT_APP_SERVICE_DVDSTORE}dvd/`, options)
            .then((response) => {
                if (response.status != 200) {
                    return Promise.reject<string>(`fail to add DVD, status ${response.status}`);
                } else {
                    return response.json();
                }
            })
            .then((dvds) => {
                dispatch(deleteDvdSuccess(dvds));
            }, (reason) => {
                dispatch(deleteDvdFail(reason));
            });
    };
}

export default deleteDvd;