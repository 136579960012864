import {RootState} from "../redux/store";
import {hideModal} from "../redux/actions/AppActions";
import searchDvdThunkAction from "../redux/actions/searchDvdThunkAction";

export interface IStateProps {
    show: boolean;
}

export const mapStateToProps = (state: RootState): IStateProps => {
    return {
        show: state.user.modal.name === "scanner" && state.user.modal.opened,
    }
};

export interface IDispatchProps {
    close: () => void;
    searchDvd: (ean: string) => void;
}

export const mapDispatchToProps: IDispatchProps = {
    close: () => hideModal("scanner"),
    searchDvd: searchDvdThunkAction,
};
