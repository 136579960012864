import React, {PropsWithChildren} from "react";
import {RouteComponentProps} from "react-router";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";

type Props = RouteComponentProps<{ dvdId: string }>

export const DvdDetailsComponent: React.FC<Props> = (props: PropsWithChildren<Props>) => {

    return (
        <div className="App">
            <Container className="bodyContent">
                <Navbar bg="dark" variant="dark">
                    <Button variant="outline-success"
                            className="ml-2">
                        Something
                    </Button>
                    <Nav className="ml-auto"/>
                    <Button variant="outline-success"
                            className="ml-2">
                        Supprimer
                    </Button>
                </Navbar>
            </Container>
        </div>
    );
};

export default DvdDetailsComponent;