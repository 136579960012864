import retrieveTokenAction from "../redux/actions/retrieveTokenThunkAction";
import {RootState} from "../redux/store/index";

export interface IDispatchProps {
    retrieveToken: (code: string) => void;
}

export const mapDispatchToProps: IDispatchProps = {
    retrieveToken: retrieveTokenAction,
};

export interface IStateProps {
    bearer: string;
}

export const mapStateToProps = (state: RootState): IStateProps => {
    return {
        bearer: state.services.bearer,
    }
};