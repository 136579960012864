import React from 'react';
import './App.css';
import './Bootstrap.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {IDispatchProps, mapDispatchToProps} from "./App.redux";
import {connect} from "react-redux";
import {RootState} from "./redux/store/index";
import WelcomeView from "./component/WelcomeView";
import CookieConsent from "react-cookie-consent";

type Props = IDispatchProps;

export const AppComponent: React.FC<Props> = (props: Props) => {

    React.useEffect(() => {
        props.wake("Bibliothèque Dvds");
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <WelcomeView/>
            </header>
            <CookieConsent
                location="top"
                overlay={true}
                buttonText="Accepter"
                disableButtonStyles={true}
                buttonClasses="btn btn-primary mr-2"
                declineButtonText="Refuser"
                declineButtonClasses="btn btn-secondary ml-2 mr-5"
                enableDeclineButton={true}
                flipButtons={true}>
                Les cookies permettent de ne pas s'authentifier à chaque venue sur le site
            </CookieConsent>
        </div>
    );
}

export default connect<{}, IDispatchProps, {}, RootState>(null, mapDispatchToProps)(AppComponent);
