import React from "react";
import "./DvdAdd.css";
import {IDispatchProps, IStateProps, mapDispatchToProps, mapStateToProps} from "./DvdAdd.redux";
import {RootState} from "../redux/store/index";
import {Redirect} from "react-router";
import {Dvd} from "../redux/dto/Dvd";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import ModalList from "../component/ModalList";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DvdCard from "../component/DvdCard";
import AlertDismissible from "../component/AlertDismissible";
import ModalScanner from "../component/ModalScanner";
import DvdGrid from "../component/DvdGrid";

type Props = IDispatchProps & IStateProps;

export const DvdAddComponent: React.FC<Props> = (props: Props) => {

    React.useEffect(() => {
        props.fetchDvds();
    }, []); // second parameter empty to ensure effect is called only once.

    const cards = props.dvds.map((dvd: Dvd, index: number) => {
        return <DvdCard dvd={dvd} key={index}/>;
    });

    if (props.bearer.trim() === '') {
        return <Redirect to={"/"}/>
    } else {
        return (
            <div className="App">
                <ModalList
                    show={props.searchResult.length > 0}
                    dvds={props.searchResult}
                />
                <Container className="bodyContent" fluid="xl">
                    <Navbar bg="dark" variant="dark" sticky="top">
                        <Form inline>
                            <FormControl
                                type="text"
                                placeholder="DVD à ajouter"
                                className="mr-sm-2"
                                value={props.ean}
                                onChange={(e: any) => props.setEan((e.target as HTMLInputElement).value)}/>
                            <Button variant="outline-success"
                                    onClick={() => props.searchEan(props.ean)}>
                                Rechercher
                            </Button>
                            <Button variant="outline-success"
                                    className="ml-2"
                                    onClick={() => props.displayScanner()}>
                                Camera
                            </Button>

                        </Form>
                    </Navbar>
                    {props.waiting &&
                    <Row>
                        <Col>
                            <Spinner animation="grow" variant="primary" className={"mt-5"}/>
                        </Col>
                    </Row>}
                    {!props.waiting &&
                    <DvdGrid>
                        {cards}
                    </DvdGrid>
                    }
                </Container>
                <AlertDismissible message={props.message}/>
                <ModalScanner/>
            </div>
        );
    }

};

export default connect<IStateProps, IDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(DvdAddComponent);
