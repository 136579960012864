import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {RootState} from "../store/index";
import {Action} from "redux";
import {setBearer} from "./AppActions";

type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

function retrieveToken(code: string): ThunkResult<void> {

    const basicAuth = `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`;
    const redirectUri = `${process.env.REACT_APP_SITE_URL}login/`;
    const options = {
        body: "",
        headers: new Headers({
            "Authorization": `Basic ${btoa(basicAuth)}`,
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        }),
        method: "post",
    };

    return (dispatch: ThunkDispatch<RootState, undefined, Action>, getState: () => RootState) => {
        const params = new Map<string, string>();
        params.set('grant_type', 'authorization_code');
        params.set('client_id', `${process.env.REACT_APP_CLIENT_ID}`);
        params.set('client_secret', `${process.env.REACT_APP_CLIENT_SECRET}`);
        params.set('redirect_uri', redirectUri);
        params.set('code', code);

        let stringParams = "";
        params.forEach((value: string, key: string) => {
            stringParams += `${key}=${value}&`;
        });
        stringParams = stringParams.substring(0, stringParams.length - 1);
        options.body = stringParams;

        fetch(`${process.env.REACT_APP_SERVICE_TOKEN}`, options)
            .then((response) => {
                if (response.status != 200) {
                    return Promise.reject<string>(`fail to solve bearer, status ${response.status}`);
                } else {
                    return response.json();
                }
            })
            .then((bearer) => {
                dispatch(setBearer(bearer.access_token));
            }, (reason) => {
                console.log(reason);
                dispatch(setBearer(""));
            });
    };
}

export default retrieveToken;