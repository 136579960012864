import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {RootState} from "../store/index";
import {Action} from "redux";
import {fetchEanFail, fetchEanPending, fetchEanSuccess} from "./AppActions";

type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

function searchDvd(ean: string): ThunkResult<void> {

    return (dispatch: ThunkDispatch<RootState, undefined, Action>, getState: () => RootState) => {
        const bearer = getState().services.bearer;
        const options = {
            headers: new Headers({
                "Content-Type": "application/json;charset=UTF-8",
            }),
            method: "get",
        };

        dispatch(fetchEanPending(ean));
        fetch(`${process.env.REACT_APP_SERVICE_EAN}?ean=${ean}`, options)
            .then((response) => {
                if (response.status != 200) {
                    return Promise.reject<string>(`fail to fetch DVD from EAN, status ${response.status}`);
                } else {
                    return response.json();
                }
            })
            .then((dvds) => {
                dispatch(fetchEanSuccess(dvds));
            }, (reason) => {
                dispatch(fetchEanFail(reason));
            });
    };
}

export default searchDvd;