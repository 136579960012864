import React, {Component} from "react";
import {Route} from "react-router";
import DvdDetails from "../pages/DvdDetails";

class DvdRouter extends Component {
    public render(){
        return <Route
                  exact={true}
                  path="/dvd/:dvdId"
                  render={(props) => <DvdDetails {...props}/>}
              />;
    }
}

export default  DvdRouter;
