import {cancelAdd} from "../redux/actions/AppActions";
import {Dvd} from "../redux/dto/Dvd";
import addDvd from "../redux/actions/AddDvdThunkAction";

export interface IDispatchProps {
    onCancel: () => void;
    onSave: (dvd: Dvd) => void;
}

export const mapDispatchToProps: IDispatchProps = {
    onCancel: cancelAdd,
    onSave: addDvd,
};