import React from 'react';
import MicroservicesLoadingView from '../component/MicroservicesLoadingView';
import { IStateProps, mapStateToProps } from './Services.redux';
import { RootState } from '../redux/store/index';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

type Props = IStateProps;

export const ServicesComponent: React.FC<Props> = (props: Props) => {
    if (props.loading) {
        return (
            <div className="App">
                <header className="App-header">
                    <MicroservicesLoadingView show={true} />
                </header>
            </div>
        );
    } else {
        return (
            <Redirect to={"/add"} />
        );
    }
};

export default connect<IStateProps, {}, {}, RootState>(mapStateToProps)(ServicesComponent);