import {Dvd} from "../dto/Dvd";
import {AppActions, IDataAction, Type} from "../actions/AppActions";

export interface IEanState {
    results: Dvd[];
    ean: string;
}

export const initialServicesState: IEanState = {
    results: [],
    ean: "",
};


export function eanStateReducer(state: IEanState = initialServicesState, action: AppActions): IEanState {
    return {
        ...state,
        ean: eanReducer(state, action),
        results: resultsReducer(state, action)
    };
}

function eanReducer(state: IEanState, action: AppActions) {
    let ean = state.ean;

    switch (action.type) {
        case Type.EAN_SET:
        case Type.EAN_FETCH:
            ean = (action as IDataAction<string>).data;
            break;
        case Type.EAN_CANCEL:
        case Type.DVD_ADD:
            ean = "";
            break;
    }
    return ean;
}

function resultsReducer(state: IEanState, action: AppActions) {
    let dvds = state.results.slice();

    switch (action.type) {
        case Type.EAN_FETCH:
        case Type.EAN_CANCEL:
        case Type.DVD_ADD:
            dvds = [];
            break;
        case Type.EAN_FETCH_SUCCESS:
            dvds = (action as IDataAction<Dvd[]>).data;
            break;
    }
    return dvds;
}
