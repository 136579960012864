import React from "react";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import Modal from "react-bootstrap/Modal";
import "./ModalScanner.css"
import {connect} from "react-redux";
import {RootState} from "../redux/store";
import {IDispatchProps, IStateProps, mapDispatchToProps, mapStateToProps} from "./ModalScanner.redux";

type Props = IDispatchProps & IStateProps;

export const ModalScannerComponent: React.FC<Props> = (props: Props) => {

    return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            onHide={props.close}
            dialogClassName="scanner">
            <Modal.Header closeButton>
                <Modal.Title>Scanner votre Dvd</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BarcodeScannerComponent
                    width={500}
                    height={500}
                    onUpdate={(err, result) => {
                        if (result) {
                            props.searchDvd(result.getText());
                        }
                    }}
                />
            </Modal.Body>
        </Modal>
    );
}

export default connect<IStateProps, IDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(ModalScannerComponent);