import React, {useState} from "react";
import Modal, {ModalProps} from "react-bootstrap/Modal";
import {Dvd} from "../redux/dto/Dvd";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {RootState} from "../redux/store/index";
import {IDispatchProps, mapDispatchToProps} from "./ModalList.redux";

interface IOwnProps {
    dvds: Dvd[];
}

type Props = IOwnProps & ModalProps & IDispatchProps;

export const ModalListComponent: React.FC<Props> = (props: Props) => {

    const [value, setValue] = useState(0);

    const items = props.dvds.map((dvd, index) => {
        return (
            <ListGroup.Item
                key={index}
                action={true}
                href={`#link${index + 1}`}
                onClick={() => setValue(index)}
            >
                {dvd.title}
            </ListGroup.Item>
        );
    });

    const handleSave = () => {
        props.onSave(props.dvds[value]);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            onHide={() => props.onCancel()}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Dvds correspondant
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup defaultActiveKey="#link1">
                    {items}
                </ListGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>Ajouter le dvd</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default connect<{}, IDispatchProps, {}, RootState>(null, mapDispatchToProps)(ModalListComponent);