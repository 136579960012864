import React, {ReactNode, ReactNodeArray} from "react";
import CardColumns from "react-bootstrap/CardColumns";
import "./DvdCard.css"

export const DvdGridComponent: React.FC = (props) => {
    const content: ReactNode[] = React.Children.toArray(props.children);
    var rowCount = Math.floor(content.length/3);
    var remainder = content.length % 3;
    if(rowCount >= 1 && remainder === 1) {
        content.splice((rowCount + 1) * 2 - 1,0,<div className="empty" key={-1}>&nbsp;</div>)
    }
    return (
        <CardColumns>
            {content}
        </CardColumns>
    );
}

export default DvdGridComponent;
