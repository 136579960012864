import {Action} from "redux";
import {Dvd} from "../dto/Dvd";

export const Type = {
    DVD_ADD: "DVD_ADD",
    DVD_ADD_ERROR: "DVD_ADD_ERROR",
    DVD_ADD_SUCCESS: "DVD_ADD_SUCCESS",
    DVD_DELETE: "DVD_DELETE",
    DVD_DELETE_ERROR: "DVD_DELETE_ERROR",
    DVD_DELETE_SUCCESS: "DVD_DELETE_SUCCESS",
    DVD_FETCH: "DVD_FETCH",
    DVD_FETCH_ERROR: "DVD_FETCH_ERROR",
    DVD_FETCH_SUCCESS: "DVD_FETCH_SUCCESS",
    EAN_CANCEL: "EAN_CANCEL",
    EAN_FETCH: "EAN_FETCH",
    EAN_FETCH_ERROR: "EAN_FETCH_ERROR",
    EAN_FETCH_SUCCESS: "EAN_FETCH_SUCCESS",
    EAN_SET: "EAN_SET",
    SERVICE_WAKE: "SERVICE_WAKE",
    SERVICE_WAKE_ERROR: "SERVICE_WAKE_ERROR",
    SERVICE_WAKE_SUCCESS: "SERVICE_WAKE_SUCCESS",
    SET_BEARER: "SET_BEARER",
    SET_ERROR: "SET_ERROR",
    MODAL_SHOW: "MODAL_SHOW",
    MODAL_HIDE: "MODAL_HIDE",
};

export interface IDataAction<T> extends Action {
    type: string;
    data: T;
}

export function fetchServiceFail(name: string): IDataAction<string> {
    return {
        type: Type.SERVICE_WAKE_ERROR,
        data: name,
    };
}

export function fetchServicePending(name: string): IDataAction<string> {
    return {
        type: Type.SERVICE_WAKE,
        data: name,
    };
}

export function fetchServiceSuccess(name: string): IDataAction<string> {
    return {
        type: Type.SERVICE_WAKE_SUCCESS,
        data: name,
    };
}

export function fetchEanFail(error: string): IDataAction<string> {
    return {
        type: Type.EAN_FETCH_ERROR,
        data: error,
    };
}

export function fetchEanPending(ean: string): IDataAction<string> {
    return {
        type: Type.EAN_FETCH,
        data: ean,
    };
}

export function fetchEanSuccess(dvds: Dvd[]): IDataAction<Dvd[]> {
    return {
        type: Type.EAN_FETCH_SUCCESS,
        data: dvds,
    };
}

export function setBearer(name: string): IDataAction<string> {
    return {
        type: Type.SET_BEARER,
        data: name,
    };
}

export function setEAN(ean: string): IDataAction<string> {
    return {
        type: Type.EAN_SET,
        data: ean,
    };
}

export function cancelAdd(): Action {
    return {
        type: Type.EAN_CANCEL,
    }
}

export function setError(error: string): IDataAction<string> {
    return {
        type: Type.SET_ERROR,
        data: error,
    };
}

export function addDvdFail(error: string): IDataAction<string> {
    return {
        type: Type.DVD_ADD_ERROR,
        data: error,
    };
}

export function addDvdPending(): Action {
    return {
        type: Type.DVD_ADD,
    };
}

export function addDvdSuccess(dvds: Dvd[]): IDataAction<Dvd[]> {
    return {
        type: Type.DVD_ADD_SUCCESS,
        data: dvds,
    };
}

export function fetchDvdsFail(error: string): IDataAction<string> {
    return {
        type: Type.DVD_FETCH_ERROR,
        data: error,
    };
}

export function fetchDvdsPending(): Action {
    return {
        type: Type.DVD_FETCH,
    };
}

export function fetchDvdsSuccess(dvds: Dvd[]): IDataAction<Dvd[]> {
    return {
        type: Type.DVD_FETCH_SUCCESS,
        data: dvds,
    };
}

export function deleteDvdFail(error: string): IDataAction<string> {
    return {
        type: Type.DVD_DELETE_ERROR,
        data: error,
    };
}

export function deleteDvdPending(): Action {
    return {
        type: Type.DVD_DELETE,
    };
}

export function deleteDvdSuccess(dvds: Dvd[]): IDataAction<Dvd[]> {
    return {
        type: Type.DVD_DELETE_SUCCESS,
        data: dvds,
    };
}

export function displayModal(modalName: string): IDataAction<string> {
    return {
        type: Type.MODAL_SHOW,
        data: modalName,
    }
}

export function hideModal(modalName: string): IDataAction<string> {
    return {
        type: Type.MODAL_HIDE,
        data: modalName,
    }
}

export type AppActions = Action | IDataAction<string> | IDataAction<Dvd[]>;
