import {RootState} from "../redux/store/index";
import wakeServiceThunkAction from "../redux/actions/wakeServiceThunkAction";

export interface IStateProps {
    loading: boolean;
}

export const mapStateToProps = (state: RootState): IStateProps => {
    let loading: boolean = false;
    state.services.all.forEach((service) => {
        loading = loading || (service.state !== 1 && service.mandatory);
    });

    return {
        loading,
    }
};
