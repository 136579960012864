import wakeServiceThunkAction from "../redux/actions/wakeServiceThunkAction";
import {RootState} from "../redux/store/index";
import {IMicroserviceLoadingViewProps} from "./MicroserviceLoadingView";

export interface IDispatchProps {
    wakeAction: (service: string) => void;
}

export const mapDispatchToProps: IDispatchProps = {
    wakeAction: wakeServiceThunkAction,
};

export interface IStateProps {
    serviceState: string;
}

export const mapStateToProps = (state: RootState, ownProps: IMicroserviceLoadingViewProps): IStateProps => {
    const service = state.services.all.get(ownProps.name);
    return {
        serviceState: service ? `${service.state}` : "-1",
    }
};