import {combineReducers, Reducer} from "redux";
import {IServiceState, servicesReducer} from "../types/ServicesState";
import {eanStateReducer, IEanState} from "../types/EanState";
import {IUserState, userStateReducer} from "../types/UserState";

export interface RootState {
    services: IServiceState;
    search: IEanState;
    user: IUserState,
}

// @ts-ignore
export const rootReducer: Reducer<RootState> = combineReducers({
    services: servicesReducer,
    search: eanStateReducer,
    user: userStateReducer,
});