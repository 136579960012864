import {Dvd} from "../redux/dto/Dvd";
import React from "react";
import Card from "react-bootstrap/Card";
import "./DvdCard.css";
import {connect} from "react-redux";
import {IDispatchProps, mapDispatchToProps} from "./DvdCard.redux";
import {RootState} from "../redux/store/index";
import {Link} from "react-router-dom";

interface IOwnProps {
    dvd: Dvd;
}

type Props = IOwnProps & IDispatchProps;

export const DvdCardComponent: React.FC<Props> = (props: Props) => {

    return (
        <Card className={"dvdCard"}>
            <Card.Img variant="top" src={props.dvd.cover}/>
            <Card.Body>
                <Card.Subtitle>
                    {props.dvd.year}
                </Card.Subtitle>
                <Card.Title style={{height: "60px"}}>{props.dvd.title}</Card.Title>
                <Link to={`/dvd/${props.dvd.ean}`}>Détails</Link>
            </Card.Body>
        </Card>
    );
};

export default connect<{}, IDispatchProps, {}, RootState>(null, mapDispatchToProps)(DvdCardComponent);