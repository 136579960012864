import {AppActions, IDataAction, Type} from "../actions/AppActions";
import {Dvd} from "../dto/Dvd";

export interface IUserState {
    dvds: Dvd[];
    error: string;
    waiting: boolean;
    modal: {name: string, opened: boolean};
}

export const initialServicesState: IUserState = {
    dvds: [],
    error: "",
    waiting: false,
    modal: {name: "", opened: false},
};

export function userStateReducer(state: IUserState = initialServicesState, action: AppActions): IUserState {
    return {
        ...state,
        error: errorReducer(state, action),
        dvds: dvdsReducer(state, action),
        modal: modalReducer(state, action),
        waiting: waitingReducer(state, action),
    };
}

function dvdsReducer(state: IUserState, action: AppActions) {
    let dvds = state.dvds.slice();

    switch (action.type) {
        case Type.DVD_ADD_SUCCESS:
        case Type.DVD_FETCH_SUCCESS:
        case Type.DVD_DELETE_SUCCESS:
            dvds = (action as IDataAction<Dvd[]>).data;
            break;
    }
    return dvds;
}

function errorReducer(state: IUserState, action: AppActions) {
    let error = state.error;

    switch (action.type) {
        case Type.EAN_FETCH_ERROR:
        case Type.SET_ERROR:
        case Type.DVD_ADD_ERROR:
        case Type.DVD_FETCH_ERROR:
        case Type.DVD_DELETE_ERROR:
            error = (action as IDataAction<string>).data;
            break;
    }
    return error;
}

function waitingReducer(state: IUserState, action: AppActions) {
    let waiting = state.waiting;

    switch (action.type) {
        case Type.DVD_ADD_SUCCESS:
        case Type.DVD_FETCH_SUCCESS:
        case Type.DVD_DELETE_SUCCESS:
        case Type.DVD_FETCH_ERROR:
        case Type.DVD_ADD_ERROR:
        case Type.DVD_DELETE_ERROR:
            waiting = false;
            break;
        case Type.DVD_ADD:
        case Type.DVD_FETCH:
        case Type.DVD_DELETE:
            waiting = true;
            break;
    }
    return waiting;
}

function modalReducer(state: IUserState, action: AppActions) {
    let modal = state.modal;

    switch (action.type) {
        case Type.MODAL_SHOW:
            modal = {name: (action as IDataAction<string>).data, opened: true};
            break;
        case Type.MODAL_HIDE:
            modal = {name: (action as IDataAction<string>).data, opened: false};
            break;
        case Type.EAN_FETCH:
            modal = {name: "", opened: false};
            break;
    }
    return modal;
}
