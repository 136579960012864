import React from 'react';
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

const authUrl = process.env.REACT_APP_SERVICE_AUTH;
const clientUrl = process.env.REACT_APP_SITE_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const scope = process.env.REACT_APP_SCOPE;
const audience = process.env.REACT_APP_AUDIENCE;

function login() {
    window.location.href = `${authUrl}response_type=code&&scope=${scope}&client_id=${clientId}&redirect_uri=${clientUrl}login/${audience}`;
}

// https://dvdstore.eu.auth0.com/Authorize?response_type=token&client_id=qmc4ANFaY4PvQcHI2vZv65dVo0i6ligP&redirect_uri=http://localhost:8080&audience=dvdstore&scope=write:dvd
// http://localhost:8083/oauth/authorize?response_type=token&client_id=newClient&scope=write:dvd&redirect_uri=http://localhost:3000/login/

const WelcomeView: React.FC = () => {
    return (
        <Jumbotron>
            <h1>Dvdthèque</h1>
            <p>
                Bibliothèque de classement et de prêt de DVDs,<br/>
                pour ceux qui en ont mare d'en perdre :).
            </p>
            <p>
                <Button variant="primary" onClick={() => login()}>Connection</Button>
            </p>
        </Jumbotron>
    );
};

export default WelcomeView;
