import React from 'react';
import Jumbotron from "react-bootstrap/Jumbotron";
import {IDispatchProps, IStateProps, mapDispatchToProps, mapStateToProps} from "./Login.redux";
import {connect} from "react-redux";
import {RootState} from "../redux/store/index";
import {Redirect} from "react-router";

type Props = IDispatchProps & IStateProps;

export const LoginComponent: React.FC<Props> = (props: Props) => {

    function retrieveBearer() {
        const codeIndex = window.location.href.indexOf('code');
        const code = window.location.href.substring(codeIndex + 5);
        props.retrieveToken(code);
    }

    let message = <p>Authentification en cours...</p>;
    if (props.bearer === "") {
        retrieveBearer();
    } else {
        message = <Redirect to={"/services"}/>;
    }
    return (
        <div className="App">
            <header className="App-header">
                <Jumbotron>
                    {message}
                </Jumbotron>
            </header>
        </div>
    );
};

export default connect<IStateProps, IDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(LoginComponent);
