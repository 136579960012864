import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {IDispatchProps, IStateProps, mapDispatchToProps, mapStateToProps} from "./MicroserviceLoadingView.redux";
import {RootState} from "../redux/store/index";

export interface IMicroserviceLoadingViewProps {
    name: string;
}

type Props = IMicroserviceLoadingViewProps & IDispatchProps & IStateProps;
export const MicroserviceLoadingViewComponent: React.FC<Props> = (props: Props) => {

    React.useEffect(() => {
        props.wakeAction(props.name);
    }, [props.name]);

    let circle = <CircularProgress size={20} variant={"indeterminate"} value={100} color={"primary"}/>;
    let libelle = `Chargement ${props.name} ...`;
    if (props.serviceState === "1") {
        circle = <CircularProgress size={20} variant={"determinate"} value={100} color={"inherit"}/>;
        libelle = `${props.name} prêt à fonctionner`
    } else if (props.serviceState === "-1") {
        circle = <CircularProgress size={20} variant={"determinate"} value={100} color={"secondary"}/>;
        libelle = `${props.name} ne répond pas`
    }

    return (
        <Row sm={12}>
            <Col sm={1} style={{color: "#00FF00"}}>
                {circle}
            </Col>
            <Col sm={11} className={"serviceMessage"}>
                {libelle}
            </Col>
        </Row>
    );
};

export default connect<IStateProps, IDispatchProps, IMicroserviceLoadingViewProps, RootState>(mapStateToProps, mapDispatchToProps)(MicroserviceLoadingViewComponent);
