import {AppActions, IDataAction, Type} from "../actions/AppActions";
import {Cookies, getCookieConsentValue} from "react-cookie-consent";

type ServiceType = { url: string, state: number, mandatory: boolean };

const services = new Map();
services.set("Bibliothèque Dvds", {url: `${process.env.REACT_APP_SERVICE_DVDSTORE}dvd/`, state: 0, mandatory: true});
services.set("Fiches Dvd", {url: `${process.env.REACT_APP_SERVICE_DVDSTORE}fiche/`, state: 0, mandatory: false});
services.set("Gateway Dvds", {url: process.env.REACT_APP_SERVICE_DVDSTORE, state: 0, mandatory: false});

let bearerValue = "";
if (getCookieConsentValue() && Cookies.get("bearerToken")) {
    bearerValue = Cookies.get("bearerToken");
}

export interface IServiceState {
    all: Map<string, ServiceType>;
    bearer: string;
}

export const initialServicesState: IServiceState = {
    all: services,
    bearer: bearerValue,
};

export function servicesReducer(state: IServiceState = initialServicesState, action: AppActions): IServiceState {
    return {
        ...state,
        all: allReducer(state, action),
        bearer: bearer(state, action)
    };
}

function allReducer(state: IServiceState, action: AppActions) {
    const all: Map<string, ServiceType> = new Map(state.all);
    let service: ServiceType | undefined = all.get((action as IDataAction<string>).data);

    switch (action.type) {
        case Type.SERVICE_WAKE:
            if (service) {
                service.state = 0;
            }
            break;
        case Type.SERVICE_WAKE_ERROR:
            if (service) {
                service.state = -1;
            }
            break;
        case Type.SERVICE_WAKE_SUCCESS:
            if (service) {
                service.state = 1;
            }
            break;
    }

    return all;
}

function bearer(state: IServiceState, action: AppActions) {
    switch (action.type) {
        case Type.SET_BEARER:
            bearerValue = (action as IDataAction<string>).data;
            if (getCookieConsentValue()) {
                Cookies.set("bearerToken", bearerValue);
            }
            return bearerValue;
        default:
            return state.bearer;
    }
}
