import searchDvdThunkAction from "../redux/actions/searchDvdThunkAction";
import {displayModal, setEAN} from "../redux/actions/AppActions";
import {RootState} from "../redux/store/index";
import {Dvd} from "../redux/dto/Dvd";
import fetchDvdThunkAction from "../redux/actions/fetchDvdThunkAction";

export interface IDispatchProps {
    searchEan: (ean: string) => void;
    fetchDvds: () => void;
    setEan: (ean: string) => void;
    displayScanner: () => void;
}

export const mapDispatchToProps: IDispatchProps = {
    searchEan: searchDvdThunkAction,
    fetchDvds: fetchDvdThunkAction,
    setEan: setEAN,
    displayScanner: () => displayModal("scanner"),
};

export interface IStateProps {
    bearer: string;
    ean: string;
    searchResult: Dvd[];
    dvds: Dvd[];
    message: string;
    waiting: boolean;
}

export const mapStateToProps = (state: RootState): IStateProps => {
    return {
        bearer: state.services.bearer,
        ean: state.search.ean,
        searchResult: state.search.results,
        dvds: state.user.dvds,
        message: state.user.error,
        waiting: state.user.waiting,
    }
};
